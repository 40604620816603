.normal-55 {
  font-size: calc(18px + 1.91vw);
}
.medium-55 {
  font-size: calc(18px + 1.91vw);
  font-weight: 500;
}
.semibold-55 {
  font-size: calc(18px + 1.91vw);
  font-weight: 600;
}
.bold-55 {
  font-size: calc(18px + 1.91vw);
  font-weight: 600;
}
.normal-50 {
  font-size: calc(17px + 1.736vw);
}
.medium-50 {
  font-size: calc(17px + 1.736vw);
  font-weight: 500;
}
.semibold-50 {
  font-size: calc(17px + 1.736vw);
  font-weight: 600;
}
.bold-50 {
  font-size: calc(17px + 1.736vw);
  font-weight: 600;
}
.normal-45 {
  font-size: calc(15px + 1.563vw);
}
.medium-45 {
  font-size: calc(15px + 1.563vw);
  font-weight: 500;
}
.semibold-45 {
  font-size: calc(15px + 1.563vw);
  font-weight: 600;
}
.normal-40 {
  font-size: calc(13px + 1.389vw);
}
.medium-40 {
  font-size: calc(13px + 1.389vw);
  font-weight: 500;
}
.semibold-40 {
  font-size: calc(13px + 1.389vw);
  font-weight: 600;
}
.bold-40 {
  font-size: calc(13px + 1.389vw);
  font-weight: 700;
}
.normal-38 {
  font-size: calc(13px + 1.319vw);
}
.medium-38 {
  font-size: calc(13px + 1.319vw);
  font-weight: 500;
}
.semibold-38 {
  font-size: calc(13px + 1.319vw);
  font-weight: 600;
}
.normal-32 {
  font-size: calc(11px + 1.111vw);
}
.medium-32 {
  font-size: calc(11px + 1.111vw);
  font-weight: 500;
}
.semibold-32 {
  font-size: calc(11px + 1.111vw);
  font-weight: 600;
}
.normal-30 {
  font-size: calc(10px + 1.042vw);
}
.medium-30 {
  font-size: calc(10px + 1.042vw);
  font-weight: 500;
}
.semibold-30 {
  font-size: calc(10px + 1.042vw);
  font-weight: 600;
}
.normal-28 {
  font-size: calc(9px + 0.972vw);
}
.medium-28 {
  font-size: calc(9px + 0.972vw);
  font-weight: 500;
}
.semibold-28 {
  font-size: calc(9px + 0.972vw);
  font-weight: 600;
}
.normal-26 {
  font-size: calc(9px + 0.903vw);
}
.medium-26 {
  font-size: calc(9px + 0.903vw);
  font-weight: 500;
}
.semibold-26 {
  font-size: calc(9px + 0.903vw);
  font-weight: 600;
}
.bold-26 {
  font-size: calc(9px + 0.903vw);
  font-weight: 700;
}
.normal-24 {
  font-size: calc(8px + 0.833vw);
}
.medium-24 {
  font-size: calc(8px + 0.833vw);
  font-weight: 500;
}
.semibold-24 {
  font-size: calc(8px + 0.833vw);
  font-weight: 600;
}
.normal-22 {
  font-size: calc(7px + 0.764vw);
}
.medium-22 {
  font-size: calc(7px + 0.764vw);
  font-weight: 500;
}
.semibold-22 {
  font-size: calc(7px + 0.764vw);
  font-weight: 600;
}
.normal-20 {
  font-size: calc(7px + 0.694vw);
}
.medium-20 {
  font-size: calc(7px + 0.694vw);
  font-weight: 500;
}
.semibold-20 {
  font-size: calc(7px + 0.694vw);
  font-weight: 600;
}
.normal-18 {
  font-size: calc(6px + 0.625vw);
}
.medium-18 {
  font-size: calc(6px + 0.625vw);
  font-weight: 500;
}
.semibold-18 {
  font-size: calc(6px + 0.625vw);
  font-weight: 600;
}
.normal-16 {
  font-size: calc(5px + 0.556vw);
}
.medium-16 {
  font-size: calc(5px + 0.556vw);
  font-weight: 500;
}
.semibold-16 {
  font-size: calc(5px + 0.556vw);
  font-weight: 600;
}
.normal-14 {
  font-size: calc(5px + 0.486vw);
}
.medium-14 {
  font-size: calc(5px + 0.486vw);
  font-weight: 500;
}
.semibold-14 {
  font-size: calc(5px + 0.486vw);
  font-weight: 600;
}
.normal-12 {
  font-size: calc(4px + 0.417vw);
}
.medium-12 {
  font-size: calc(4px + 0.417vw);
  font-weight: 500;
}
.semibold-12 {
  font-size: calc(4px + 0.417vw);
  font-weight: 600;
}
@media screen and (max-width: 576px) {
  .normal-32 {
    font-size: 19px;
  }
  .medium-32 {
    font-size: 19px;
    font-weight: 500;
  }
  .semibold-32 {
    font-size: 19px;
    font-weight: 600;
  }
  .normal-30 {
    font-size: 18px;
  }
  .medium-30 {
    font-size: 18px;
    font-weight: 500;
  }
  .semibold-30 {
    font-size: 18px;
    font-weight: 600;
  }
  .normal-28 {
    font-size: 17px;
  }
  .medium-28 {
    font-size: 17px;
    font-weight: 500;
  }
  .semibold-28 {
    font-size: 17px;
    font-weight: 600;
  }
  .normal-26 {
    font-size: 16px;
  }
  .medium-26 {
    font-size: 16px;
    font-weight: 500;
  }
  .semibold-26 {
    font-size: 16px;
    font-weight: 600;
  }
  .bold-26 {
    font-size: 16px;
    font-weight: 700;
  }
  .normal-24 {
    font-size: 15px;
  }
  .medium-24 {
    font-size: 15px;
    font-weight: 500;
  }
  .semibold-24 {
    font-size: 15px;
    font-weight: 600;
  }
  .normal-22 {
    font-size: 12px;
  }
  .medium-22 {
    font-size: 12px;
    font-weight: 500;
  }
  .semibold-22 {
    font-size: 12px;
    font-weight: 600;
  }
  .normal-20 {
    font-size: 13px;
  }
  .medium-20 {
    font-size: 13px;
    font-weight: 500;
  }
  .semibold-20 {
    font-size: 13px;
    font-weight: 600;
  }
  .normal-18 {
    font-size: 12px;
  }
  .medium-18 {
    font-size: 12px;
    font-weight: 500;
  }
  .semibold-18 {
    font-size: 12px;
    font-weight: 600;
  }
  .normal-16 {
    font-size: 11px;
  }
  .medium-16 {
    font-size: 11px;
    font-weight: 500;
  }
  .semibold-16 {
    font-size: 11px;
    font-weight: 600;
  }
  .normal-14 {
    font-size: 9px;
  }
  .medium-14 {
    font-size: 9px;
    font-weight: 500;
  }
  .semibold-14 {
    font-size: 9px;
    font-weight: 600;
  }
  .normal-12 {
    font-size: 8px;
  }
  .medium-12 {
    font-size: 8px;
    font-weight: 500;
  }
  .semibold-12 {
    font-size: 8px;
    font-weight: 600;
  }
}
/* @media screen and (576px <= width <= 1024px) {
  .normal-32 {
    font-size: 21px;
  }
  .medium-32 {
    font-size: 21px;
    font-weight: 500;
  }
  .semibold-32 {
    font-size: 21px;
    font-weight: 600;
  }
  .normal-30 {
    font-size: 20px;
  }
  .medium-30 {
    font-size: 20px;
    font-weight: 500;
  }
  .semibold-30 {
    font-size: 20px;
    font-weight: 600;
  }
  .normal-28 {
    font-size: 19px;
  }
  .medium-28 {
    font-size: 19px;
    font-weight: 500;
  }
  .semibold-28 {
    font-size: 19px;
    font-weight: 600;
  }
  .normal-26 {
    font-size: 17px;
  }
  .medium-26 {
    font-size: 17px;
    font-weight: 500;
  }
  .semibold-26 {
    font-size: 17px;
    font-weight: 600;
  }
  .bold-26 {
    font-size: 17px;
    font-weight: 700;
  }
  .normal-24 {
    font-size: 16px;
  }
  .medium-24 {
    font-size: 16px;
    font-weight: 500;
  }
  .semibold-24 {
    font-size: 16px;
    font-weight: 600;
  }
  .normal-22 {
    font-size: 12px;
  }
  .medium-22 {
    font-size: 12px;
    font-weight: 500;
  }
  .semibold-22 {
    font-size: 12px;
    font-weight: 600;
  }
  .normal-20 {
    font-size: 13px;
  }
  .medium-20 {
    font-size: 13px;
    font-weight: 500;
  }
  .semibold-20 {
    font-size: 13px;
    font-weight: 600;
  }
  .normal-18 {
    font-size: 12px;
  }
  .medium-18 {
    font-size: 12px;
    font-weight: 500;
  }
  .semibold-18 {
    font-size: 12px;
    font-weight: 600;
  }
  .normal-16 {
    font-size: 11px;
  }
  .medium-16 {
    font-size: 11px;
    font-weight: 500;
  }
  .semibold-16 {
    font-size: 11px;
    font-weight: 600;
  }
  .normal-14 {
    font-size: 9px;
  }
  .medium-14 {
    font-size: 9px;
    font-weight: 500;
  }
  .semibold-14 {
    font-size: 9px;
    font-weight: 600;
  }
  .normal-12 {
    font-size: 8px;
  }
  .medium-12 {
    font-size: 8px;
    font-weight: 500;
  }
  .semibold-12 {
    font-size: 8px;
    font-weight: 600;
  }
} */
/* @media screen and (576px <= width <= 991px) {
  .normal-32 {
    font-size: calc(21px + 0.556vw);
  }
  .medium-32 {
    font-size: calc(21px + 0.556vw);
    font-weight: 500;
  }
  .semibold-32 {
    font-size: calc(21px + 0.556vw);
    font-weight: 600;
  }
  .normal-30 {
    font-size: calc(20px + 0.521vw);
  }
  .medium-30 {
    font-size: calc(20px + 0.521vw);
    font-weight: 500;
  }
  .semibold-30 {
    font-size: calc(20px + 0.521vw);
    font-weight: 600;
  }
  .normal-28 {
    font-size: calc(19px + 0.486vw);
  }
  .medium-28 {
    font-size: calc(19px + 0.486vw);
    font-weight: 500;
  }
  .semibold-28 {
    font-size: calc(19px + 0.486vw);
    font-weight: 600;
  }
  .normal-26 {
    font-size: calc(17px + 0.451vw);
  }
  .medium-26 {
    font-size: calc(17px + 0.451vw);
    font-weight: 500;
  }
  .semibold-26 {
    font-size: calc(17px + 0.451vw);
    font-weight: 600;
  }
  .bold-26 {
    font-size: calc(17px + 0.451vw);
    font-weight: 700;
  }
  .normal-24 {
    font-size: calc(16px + 0.417vw);
  }
  .medium-24 {
    font-size: calc(16px + 0.417vw);
    font-weight: 500;
  }
  .semibold-24 {
    font-size: calc(16px + 0.417vw);
    font-weight: 600;
  }
  .normal-22 {
    font-size: calc(12px + 0.382vw);
  }
  .medium-22 {
    font-size: calc(12px + 0.382vw);
    font-weight: 500;
  }
  .semibold-22 {
    font-size: calc(12px + 0.382vw);
    font-weight: 600;
  }
  .normal-20 {
    font-size: calc(13px + 0.347vw);
  }
  .medium-20 {
    font-size: calc(13px + 0.347vw);
    font-weight: 500;
  }
  .semibold-20 {
    font-size: calc(13px + 0.347vw);
    font-weight: 600;
  }
  .normal-18 {
    font-size: calc(12px + 0.313vw);
  }
  .medium-18 {
    font-size: calc(12px + 0.313vw);
    font-weight: 500;
  }
  .semibold-18 {
    font-size: calc(12px + 0.313vw);
    font-weight: 600;
  }
  .normal-16 {
    font-size: calc(11px + 0.278vw);
  }
  .medium-16 {
    font-size: calc(11px + 0.278vw);
    font-weight: 500;
  }
  .semibold-16 {
    font-size: calc(11px + 0.278vw);
    font-weight: 600;
  }
  .normal-14 {
    font-size: calc(9px + 0.243vw);
  }
  .medium-14 {
    font-size: calc(9px + 0.243vw);
    font-weight: 500;
  }
  .semibold-14 {
    font-size: calc(9px + 0.243vw);
    font-weight: 600;
  }
  .normal-12 {
    font-size: calc(8px + 0.208vw);
  }
  .medium-12 {
    font-size: calc(8px + 0.208vw);
    font-weight: 500;
  }
  .semibold-12 {
    font-size: calc(8px + 0.208vw);
    font-weight: 600;
  }
} */
