.bg-styles {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.iphone-only {
  background-image: url("./images/MicrosoftTeams-image.png");
  @apply bg-styles;
}

.didnt-recive-email {
  background-image: url("./images/Email/GettyImages-869694200.png");
  @apply bg-styles;
}
.create-account {
  background-image: url("./images/GettyImages-869694200/GettyImages-869694200.png");
  @apply bg-styles;
}

.qrcode-page {
  background-image: url("./images/GettyImages-660590073/GettyImages-660590073.png");
  @apply bg-styles;
}

.sim-installation {
  background-image: url("./images/GettyImages-869694200/GettyImages-869694200.png");
  @apply bg-styles;
}

.purchase-successful {
  background-image: url("./images/GettyImages-1271969805/GettyImages-1271969805.png");
  @apply bg-styles;
}

.auto-refill {
  background-image: url("./images/GettyImages-1365589246/GettyImages-1365589246.png");
  @apply bg-styles;
  /* margin-left: -11px; */
}

.inquiry {
  background-image: url("./images/Email/GettyImages-869694200.png");
  @apply bg-styles;
}
.my-account {
  background-image: url("./images/GettyImages-1052986022/GettyImages-1052986022.png");
  @apply bg-styles;
}

.card-details {
  background-image: url("./images/GettyImages-1310953680/GettyImages-1310953680.png");
  @apply bg-styles;
}

.privacy-policy {
  background-image: url("./images/GettyImages-200117993-001/GettyImages-200117993-001.png");
  @apply bg-styles;
}

.save-payment-method {
  background-image: url("../../resources/images//GettyImages-158687146/GettyImages-158687146.png");
  @apply bg-styles;
}
.info-country {
  background-image: url("./images/GettyImages-158687146-info/GettyImages-158687146.png");
  @apply bg-styles;
}
.settings-instructions {
  background-image: url("./images/settingsInstructions.png");
  @apply bg-styles;
  background-position: 80%;
}
.ride-miles {
  background-image: url("./images/GettyImages-158687146-1.png");
  @apply bg-styles;
}

@media screen and (max-width: 576px) {
  .didnt-recive-email {
    background-image: url("./images/mobile/GettyImages-869694200/GettyImages-869694200@2x.png");
    @apply bg-styles;
  }
  .create-account {
    background-image: url("./images/mobile/GettyImages-869694200/GettyImages-869694200@2x.png");
    @apply bg-styles;
  }

  .qrcode-page {
    background-image: url("./images/mobile/GettyImages-660590073/GettyImages-660590073@2x.png");
    @apply bg-styles;
  }

  .sim-installation {
    background-image: url("./images/mobile/GettyImages-869694200/GettyImages-869694200@2x.png");
    @apply bg-styles;
  }

  .purchase-successful {
    background-image: url("./images/mobile/GettyImages-1271969805/GettyImages-1271969805@2x.png");
    @apply bg-styles;
  }

  .auto-refill {
    background-image: url("./images/mobile/GettyImages-1365589246/GettyImages-1365589246@2x.png");
    @apply bg-styles;
    /* margin-left: -11px; */
  }

  .inquiry {
    background-image: url("./images/mobile/GettyImages-869694200/GettyImages-869694200@2x.png");
    @apply bg-styles;
  }

  .save-payment-method {
    background-image: url("./images/mobile/GettyImages-158687146/GettyImages-158687146@2x.png");
    @apply bg-styles;
  }

  .card-details {
    background-image: url("./images/mobile/GettyImages-1365589246/GettyImages-1365589246@2x.png");
    @apply bg-styles;
  }

  .my-account {
    background-image: url("./images/mobile/GettyImages-660590073/GettyImages-660590073@2x.png");
    @apply bg-styles;
  }
  .info-country {
    background-image: url("./images/mobile/GettyImages-158687146/GettyImages-158687146@2x.png");
    @apply bg-styles;
  }
  /* .iphone-only {
    background-image: url("./images/MicrosoftTeams-image.png");
    @apply bg-styles;
  } */
  .ride-miles {
    background-image: url("./images/mobile/GettyImages-158687146/GettyImages-158687146@2x.png");
    @apply bg-styles;
  }
}
