@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* toast container start */
.custom-toast-container {
  @apply w-96 sm:w-full !important;
}
/* toast container end */

* {
  font-family: "Overpass", sans-serif;
}
*:focus-visible {
  @apply outline-none;
}
*:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.arrow {
  border-top: 4px solid #fdfdfd !important;
}
.up {
  border-top: none !important;
  border-bottom: 4px solid #fffdfd !important;
}
.react-tel-input .form-control {
  border: unset !important;
  font-size: calc(6px + 0.625vw) !important;
  padding-left: 3rem !important;
  width: 100%;
  background: transparent !important;
  color: white !important;
  font-family: "Overpass", sans-serif !important;
}
.react-tel-input .form-control:focus {
  box-shadow: none !important;
}
flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 0 !important;
}
.form-input {
  @apply pl-16;
}
/* .containerClass {
  border: red;
  height: full;
  margin-left: 3.5rem !important;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}
/* input field type number arrows start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* input field type number arrows end*/
/*popup component input start*/
.popup input:-webkit-autofill:focus {
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
}
.popup input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
}
/*popup component input field end*/
.iti__flag-container {
  position: absolute !important;
  left: 0 !important;
  background-color: transparent !important;
}
.iti--separate-dial-code {
  width: 100% !important;
}
.iti--allow-dropdown {
  width: 100% !important;
}
.iti--allow-dropdown input {
  background: inherit !important;
  font-weight: 300 !important;
}
.tel-input-container .iti--allow-dropdown input {
  color: white !important;
}
.modal-tel-input-container {
  color: black !important;
}
.iti__arrow {
  border-top: 4px solid #fdfdfd !important;
}
.iti__arrow--up {
  border-top: none !important;
  border-bottom: 4px solid #fffdfd !important;
}
.tel-input-container,
/* .modal-tel-input-container {
  width: 85% !important;
  margin-left: auto;
} */

.modal-tel-input-container .arrow {
  border-top: 4px solid #131212 !important;
}
.modal-tel-input-container .up {
  border-top: none !important;
  border-bottom: 4px solid #131212 !important;
}

.telephone-input {
  /* width: 100% !important; */
  border: unset !important;
  font-size: calc(6px + 0.625vw) !important;
  padding-left: 3rem !important;
  width: 100%;
}
.phone-form-control {
  height: calc(32px + 2.4vh) !important;
}
.telephone-input:focus {
  --tw-ring-shadow: 0 0 #000 !important;
  border: none !important;
}
.orange {
  color: #d05929;
}
.bg-orange {
  background-color: #d05929;
}
.teal {
  color: #52b1ae;
}
.bg-teal {
  background-color: #52b1ae;
}
.pink {
  color: #c42f52;
}
.bg-pink {
  background-color: #c42f52;
}
/*form filed component phone number start*/
/* .iti__flag-container .iti__selected-flag .iti__flag {
  display: none !important;
} */
/* .iti__flag-container {
  position: absolute !important;
  left: 80px !important;
  background-color: transparent !important;
} */
/*form filed component phone number end*/
:first-of-type .rm-font-overpass {
  font-family: "Overpass", sans-serif;
}

/* hover animation start */
.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  color: #c42f52;
  background-color: #c42f52;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover::after {
  transform: scaleX(1);
}
/* hover animation end */
.homepage-login {
  background: transparent
    url("./resources/images/AdobeStock_255852623/AdobeStock_255852623.webp") 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
}
.hompage-signup {
  background: transparent
    url("./resources/images/AdobeStock_255852623/MicrosoftTeams-image.webp") 0%
    0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
}
.tab-bg {
  background: transparent
    linear-gradient(180deg, #000000cf 0%, #000000b0 51%, #000000ce 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000099;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(23px);
  -webkit-backdrop-filter: blur(23px);
  width: 32.9vw;
}
/* Header Start */
.rm-header-container {
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
}
.rm-header-container .rm-logo {
  /* max-height: 50px; */
  /* width: 103px; */
  /* width: 103px; */
  /* width: calc(45px + 4.688vw); */
}
.rm-header-container .rm-header-login-btn {
  width: 101px;
  height: 37px;
  background-color: #c42f52;
  border-radius: 10px;
  color: #fff;
}
/* Header End */
/* Dashboard Start */
.subscription-sec-1 {
  background: transparent linear-gradient(90deg, #d05929 0%, #fc8453 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
}
.subscription-sec-2 {
  background: transparent linear-gradient(90deg, #ffffff 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001c;
  border-radius: 10px;
}
/* .rm-dashboard-img-container::before {
  content: "";
  background: url("resources/images/Group642/Group642_2x.png");
  background-size: auto calc(132px + 13.785vw);
  position: absolute;
  inset: 0;
} */
.rm-dashboard-img-container {
  height: calc(129px + 13.785vw);
  background-image: url("resources/images/landing_page.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.rm-dashboard-img-container-loggedin {
  height: calc(129px + 13.785vw);
  background-image: url("resources/images/Group940/Group\ 940.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-position: 100%;
}
.manage-plans {
  height: calc(60px + 28.193vw);
  background: linear-gradient(179deg, #00000003, #00000078),
    url("resources/images/GettyImages-200117993-001/GettyImages-200117993-001.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.rm-dashboard-img-container .rm-dashboard-text {
  /* position: absolute;
  padding-left: 262px;
  padding-right: 240px; */
  text-align: center;
  position: relative;
}
.rm-dashboard-text .text-main {
  /* font-size: 2.605vw; */
  color: #fff;
}
.rm-dashboard-text .text-secondary {
  /* font-size: 2.085vw; */
  color: #52b1ae;
  position: relative;
  text-shadow: 0 0 3pt #49494985;
}
.shop-for-plan-parent {
  padding: 3vw 0;
}
.shop-for-plan-parent .shop-plan-text {
  color: #52b1ae;
  font-family: "Overpass", sans-serif;
  text-align: center;
}
.shop-for-plan-parent .destination-text {
  font-family: "Overpass", sans-serif;
  text-align: center;
}
.shop-for-plan-parent .country-zone-select-text {
  font-family: "Overpass", sans-serif;
  text-align: center;
}
/* .country-zone-section {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #52b1ae1a;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 2vw;
} */
.dashboard-tab-section {
  background: #f4f2ee 0% 0% no-repeat padding-box;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 2vw;
  font-family: "Overpass", sans-serif;
  border-radius: 25px;
  box-shadow: 0px 0px 20px #52b1ae33;
}
.country-zone-parent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 20px #52b1ae4d;
  border-radius: 10px;
  width: 28vw;
  padding: 0.5rem;
  margin: auto;
}
.country-zone-parent .active-tab {
  background: #52b1ae 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 5px #52b1ae29, 0px 1px 10px #52b1ae29;
  border-radius: 10px;
}
.country-zone-parent .active-none-tab {
  padding: 0.5rem 3vw;
  width: 14vw;
  text-align: center;
}
.country-zone-parent .active-none-tab .country-zone-text {
  font-family: "Overpass", sans-serif;
  font-size: 1.563vw; /* 30px */
}
.country-zone-section .country-zone-search {
  background: #52b1ae12 0% 0% no-repeat padding-box;
  /* box-shadow: inset 0px 0px 10px #00000033; */
  border-radius: 13px;
  font-family: "Overpass", sans-serif;
  color: #383838;
  padding-left: 5vw;
  height: calc(22px + 2.257vw);
}
.country-zone-section .popular-destinations-text {
  font-family: "Overpass", sans-serif;
  font-size: calc(17px + 0.451vw);
  color: #52b1ae;
}
.country-zone-section .grid-card {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  box-shadow: 0px 0px 5px #52b1ae14;
  border: 1px solid #52b1ae;
  border-radius: 13px;
  padding: 0.833vw 1.25vw;
}
.country-zone-section .flag-icon {
  width: calc(17px + 0.434vw);
  height: auto;
}
.country-zone-section .country-name-text {
  font-family: "Overpass", sans-serif;
  /* color: #52b1ae; */
  opacity: 0.9;
  font-size: calc(12px + 0.313vw);
}
.right-arrow {
  width: 2vw;
}
.show-countries-btn .rounded-none {
  background: #52b1ae 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: "Overpass", sans-serif;
  padding: 0.5rem 3vw;
  color: #ffffff;
}
/* Dashboard End */
/* Footer Start */
.rm-footer {
  /* height: 423px; */
  background-color: #29282d;
}
.rm-footer .rm-logo {
  height: calc(36px + 5.625vw);
  max-width: calc(70px + 10.938vw);
  margin-top: calc(32px + 3.333vw);
}
.rm-footer-term-labels-container {
  height: 20px;
  padding: calc(14px + 1.493vw) 28.43vw;
  align-items: center;
}
.rm-footer-term-labels-container span {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.91;
}
/* .rm-footer .rm-footer-container {
  margin: 0px 239.5px;
} */
/* Footer End */
/* Otp screen Start */
.otpinput {
  background: inherit !important;
  border: 1px solid #c42f52 !important;
  border-radius: 10px !important;
  margin: 5px !important;
  color: white !important;
  height: calc(20px + 2.448vw) !important;
  width: calc(10px + 2.03vw) !important;
}
.popup .otpinput {
  background: inherit !important;
  border: 1px solid #c42f52 !important;
  border-radius: 10px !important;
  margin: 5px !important;
  color: black !important;
  height: calc(20px + 2.448vw) !important;
  width: calc(10px + 2.03vw) !important;
}
/* Otp screen End */
/* @media screen and (max-width: 1919px) {
  .rm-header-container .rm-logo {
    height: 50px;
    width: 200px;
  }
} */

.phone-input-country-popup button,
.phone-input-country-popup input,
.phone-input-country-form button,
.phone-input-country-form input {
  font-family: "Overpass", sans-serif;
  --tw-text-opacity: 1;
}
.phone-input-country-popup button span:first-child {
  color: inherit;
}

.phone-input-country-form button,
.phone-input-country-form input {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.phone-input-country-popup button,
.phone-input-country-popup input {
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-size: calc(6px + 0.625vw);
}

@media screen and (max-width: 1679px) {
  .form-button {
    @apply mt-6 py-4 text-sm;
  }
}
@media screen and (max-width: 1440px) {
  .form-input {
    @apply p-1 pl-12 text-sm;
  }
  .phone-input-country-form button,
  .phone-input-country-form input {
    font-size: 0.875rem;
  }
  .formIcon-left {
    @apply left-5;
  }
  .formIcon-right {
    @apply right-5;
  }
  .form-button {
    @apply mt-6 py-3 text-sm;
  }
  .tab-titles {
    @apply py-1 text-lg;
  }
  .formField-spacing {
    @apply space-y-4;
  }
  .tab-mb {
    @apply mb-6;
  }
  .form-bottomText {
    @apply text-xs font-light;
  }
  .signin-error {
    @apply form-bottomText px-12;
  }
  .signin-msgSection {
    @apply space-y-2 pb-4;
  }
  .tel-input-container,
  .modal-tel-input-container {
    width: 100% !important;
  }
}
/* @media screen and (max-width: 1279px) {
} */
@media screen and (max-width: 576px) {
  .rm-header-container {
    padding: 10px 15px;
  }
  .react-tel-input .form-control {
    font-size: calc(calc(7px + 1.344vw)) !important;
    color: rgb(8, 0, 0) !important;
    font-weight: 300;
  }
  .rm-dashboard-img-container {
    height: calc(60px + 28.193vw);
    background: linear-gradient(179deg, #00000003, #00000078),
      url("resources/images/landing_page.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .rm-dashboard-text .text-main {
    text-shadow: 0 0 6pt #00000085;
  }
  .text-shadow {
    text-shadow: 0 0 6pt #00000085;
  }
  .country-zone-section .grid-card {
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 5px #52b1ae14;
    border: 1px solid #52b1ae;
    border-radius: 13px;
    padding: 12px 16px;
  }
  .country-zone-parent {
    width: 85vw;
    padding: 0.2rem;
  }
  .country-zone-section .country-zone-search {
    padding-left: 10vw;
    height: calc(22px + 2.257vw);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form-button {
    @apply mt-2 py-2;
  }
  .otpinput {
    background: inherit !important;
    border: 1px solid #c42f52 !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: black !important;
    height: calc(47px + 1.04vw) !important;
    width: calc(35px + 1.04vw) !important;
  }
  .popup .otpinput {
    background: inherit !important;
    border: 1px solid #c42f52 !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: black !important;
    height: calc(47px + 1.04vw) !important;
    width: calc(35px + 1.04vw) !important;
  }
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  .formField-spacing {
    @apply space-y-6;
  }
  .iti-mobile .iti__country-list {
    max-height: 90% !important;
  }
  .formIcon-left {
    @apply left-4;
  }
  .formIcon-right {
    @apply right-4;
  }
  .telephone-input {
    border: unset !important;
    font-size: 12px !important;
    padding-left: 3rem !important;
    width: 100%;
  }
  .phone-form-control {
    height: 3rem !important;
  }
  .tel-input-container .iti--allow-dropdown input {
    color: black !important;
  }
  .modal-tel-input-container .iti__arrow {
    border-top: 4px solid #c42f52 !important;
  }

  .phone-input-country-form button,
  .phone-input-country-form input {
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.75rem;
  }

  .phone-input-country-popup button,
  .phone-input-country-popup input {
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-size: 0.875rem;
  }

  .rm-header-container .rm-header-login-btn {
    width: 65px;
    height: 32px;
    border-radius: 10px;
  }
}

@media screen and (576px <= width <= 1024px) {
  .rm-header-container .rm-header-login-btn {
    width: calc(91px + 4.427vw);
    height: calc(33px + 1.628vw);
    border-radius: 10px;
  }
  .rm-dashboard-text .text-main {
    text-shadow: 0 0 6pt #00000085;
  }
  .tab-titles {
    @apply py-1 text-lg;
  }
  .country-zone-section .country-zone-search {
    background: #52b1ae12 0% 0% no-repeat padding-box;
    padding-left: 8vw;
    border-radius: 13px;
    font-family: "Overpass", sans-serif;
    color: #383838;
    height: calc(42px + 2.257vw);
  }
  .country-zone-parent {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 20px #52b1ae4d;
    border-radius: 10px;
    width: 50vw;
    padding: 0.5rem;
    margin: auto;
  }
  .country-zone-section .flag-icon {
    width: calc(20px + 0.434vw);
    height: auto;
  }
  .country-zone-section .country-name-text {
    font-family: "Overpass", sans-serif;
    /* color: #52b1ae; */
    opacity: 0.9;
    font-size: calc(12px + 0.586vw);
  }
  .country-zone-section .grid-card {
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 5px #52b1ae14;
    border: 1px solid #52b1ae;
    border-radius: 13px;
    padding: 1.4vw 2.7vw;
  }
  .form-input {
    @apply p-1 pl-14 text-[calc(calc(7px+1.344vw))];
  }
  .phone-form-control {
    /* @apply p-1 pl-12; */
    font-size: unset;
  }
  .formField-spacing {
    @apply space-y-6;
  }
  .formIcon-left {
    @apply left-5;
  }
  .formIcon-right {
    @apply right-5;
  }
  .form-button {
    @apply mt-2 h-[calc(35px+2.016vw)] py-2 text-base font-normal;
  }
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  .otpinput {
    background: inherit !important;
    border: 1px solid #c42f52 !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: black !important;
    height: calc(40px + 2vw) !important;
    width: calc(30px + 2vw) !important;
  }
  .popup .otpinput {
    background: inherit !important;
    border: 1px solid #c42f52 !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: black !important;
    height: calc(40px + 2vw) !important;
    width: calc(30px + 2vw) !important;
  }
  .popup .otpinputTab {
    background: inherit !important;
    border: 1px solid #c42f52 !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: black !important;
    height: calc(33px + 2vw) !important;
    width: calc(22px + 2vw) !important;
  }
  .d-none-tab {
    display: none;
  }
  .tab-rm-logo {
    margin-left: 5%;
  }
  .tab-rm-options {
    margin-right: 3%;
  }
  .tab-terms-container {
    padding-left: 20%;
    padding-right: 5%;
  }
  .dashboard-tab-section {
    padding: 0.2vw;
  }
  .telephone-input {
    border: unset !important;
    font-size: calc(calc(7px + 1.344vw)) !important;
    padding-left: 3rem !important;
    width: 100%;
  }
  .phone-form-control {
    height: 4rem !important;
  }
  .show-countries-btn .rounded-none {
    padding: 0.5rem 8vw;
  }
  .tel-input-container .iti--allow-dropdown input {
    color: black !important;
  }
  .edit-phone-input .telephone-input,
  .edit-phone-input .form-input {
    font-size: calc(6px + 0.625vw) !important;
  }
  .modal-tel-input-container .iti__arrow {
    border-top: 4px solid #c42f52 !important;
  }
  .react-tel-input .form-control {
    font-size: calc(calc(7px + 1.344vw)) !important;
    color: rgb(8, 0, 0) !important;
    font-weight: 300;
  }
  .phone-input-country-form button,
  .phone-input-country-form input {
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-weight: 300;
    font-size: calc(calc(7px + 1.344vw));
    line-height: 1.75rem;
  }

  .phone-input-country-popup button,
  .phone-input-country-popup input {
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-size: calc(calc(7px + 1.344vw));
  }
  .edit-tab input {
    font-size: calc(6px + 0.625vw);
  }
  .edit-tab span {
    font-size: calc(6px + 0.625vw);
  }
}
@media screen and (min-width: 992px) {
  .d-none-web {
    display: none;
  }
}

/* @media (min-width: 1024px) {
  .ridemiles-polygon {
    transform: translateX(70px);
  }
}
@media (600px <= width < 1024px) {
  .ridemiles-polygon {
    transform: translateX(25px);
  }
} */
